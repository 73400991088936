
import { Produce, ProduceTypes } from "@/store/modules/produce/produce.types";
import { DefaultState, Pagination } from "@/types/types";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { currency, slash } from "@/filters/utils";

const produce = namespace("Produce");

@Component({
  components: {
    NoData: () => import("@/components/utils/NoData.vue"),
  },
  filters: {
    currency,
    slash,
  },
})
export default class ProducesView extends Vue {
  @Prop({ default: true }) pagination?: boolean;
  @Prop({ default: "" }) query?: string;

  @produce.State("produces")
  public produces!: Produce[];

  @produce.State("add")
  public addProduce!: DefaultState;

  @produce.State("pages")
  public pageCount!: number;

  @produce.State("loadingProduce")
  public loadingProduces!: boolean;

  @produce.Mutation(ProduceTypes.SET_ADD_PRODUCE_DIALOG)
  public setAddProduce!: (addProduce: boolean) => void;

  @produce.Action(ProduceTypes.LOAD_PRODUCES)
  public loadProduces!: (pagination: Pagination) => void;

  public page = 1;

  @Prop({ required: true, default: 10 }) public pageSize!: number;

  public headers = [
    {
      text: "NAME",
      sortable: true,
      value: "user.name",
    },
    {
      text: "QTY",
      sortable: true,
      value: "quantity",
    },
    {
      text: "UNIT",
      sortable: true,
      value: "unit",
    },
    {
      text: "CROP",
      sortable: true,
      value: "crop",
    },
    {
      text: "CREATED",
      sortable: true,
      value: "created_at",
    },
  ];

  closeAddProduceDialog(): void {
    this.setAddProduce(false);
  }

  openAddProduceDialog(): void {
    this.setAddProduce(true);
  }

  @Watch("pageSize")
  handlePageSizeChange(): void {
    this.page = 1;
    this.queryProduces();
  }

  handlePageChange(): void {
    this.queryProduces();
  }

  queryProduces(): void {
    this.loadProduces({
      page: this.page,
      limit: this.pageSize,
      query: this.query,
    });
  }

  mounted(): void {
    if (this.produces.length == 0) this.queryProduces();
  }
}
